#root {

  .patterns-form {
    background-color: @light-grey;
    padding: 30px 25px 10px;

    .custom-iterator {

      .RaSimpleFormIterator-list {
        padding: 5px;
      }

      .RaSimpleFormIterator-line {
        display: flex;
        align-items: center;
        border-bottom: none;
      }

      .RaSimpleFormIterator-form {
        display: flex;
        flex-direction: row;
        column-gap: 5%;
        width: 90%;
        max-width: 850px;
        align-items: center;
      }

      .unit {
        text-align: center;
        width: 10%;
      }
    }

    .RaSimpleFormIterator-action svg {
       width: 0.8em;
       height: 0.8em;
    }


  }
}