/* Global styles */
/*
 * Colors
 */
#root {
  /* general components styles */
}
@media (min-width: 599px) {
  #root .MuiAppBar-root {
    display: none;
  }
  #root .RaLayout-appFrame {
    margin-top: 0;
  }
}
#root #main-content {
  padding: 1.5rem;
  position: relative;
}
#root .MuiCard-root {
  margin-bottom: 12px;
  box-shadow: none !important;
}
#root .MuiCard-root.welcome {
  padding: 12px;
}
#root .MuiCard-root.welcome .MuiCardHeader-root {
  font-size: 18px;
  text-transform: capitalize;
  padding: 0 !important;
}
#root .MuiCard-root.welcome .MuiCardHeader-root .MuiTypography-root {
  font-weight: 800;
}
#root .MuiCard-root.welcome .MuiCardContent-root {
  padding: 0 !important;
}
#root {
  /*
  table  {
    border: 1px solid black;
    td, th  {
      border: 1px solid black;
    }
  }
  */
}
#root .title-medium {
  font-size: 20px;
  font-weight: 700;
}
#root .hide {
  display: none !important;
}
#root :focus-visible {
  outline: none !important;
}
#root .transparent {
  background: transparent !important;
}
#root .no-shadow {
  box-shadow: none !important;
}
#root .empty-row {
  height: 30px;
}
#root .empty {
  text-align: center;
  padding-top: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
}
@media (max-width: 599px) {
  #root .empty p {
    font-size: 20px;
  }
}
#root .pointer {
  cursor: pointer !important;
}
#root .flexbox {
  display: flex;
}
#root .error {
  color: #d32f2f;
}
#root .no-margin {
  margin: 0px !important;
}
#root .align-center {
  align-items: center;
}
.empty-list {
  text-align: center;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}
.empty-list__text {
  font-size: 24px;
}
@media (max-width: 599px) {
  .empty-list__text {
    font-size: 20px;
  }
}
.alert-panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 auto;
  padding: 24px 24px 48px;
}
.alert-panel__content {
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 12px;
}
.alert-panel__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #F7DCDC;
}
.alert-panel p {
  font-size: 24px;
}
.cache-icon {
  padding: 4px 20px !important;
  border-radius: 0 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.cache-icon.cached {
  color: #0001BE !important;
}
/* Element styles */
/* Sidebar */
#root {
  /*
  @media (max-width: 767px) {
    .MuiDrawer-root.RaSidebar-paperAnchorLeft {
      background-color: @primary;
    }
  }*/
}
#root .MuiDrawer-root,
#root .MuiDrawer-root.RaSidebar-paperAnchorLeft {
  height: 100vh;
  position: sticky;
  top: 0;
}
#root .MuiDrawer-root .RaSidebar-fixed,
#root .MuiDrawer-root.RaSidebar-paperAnchorLeft .RaSidebar-fixed {
  height: 100vh;
  position: static;
}
#root .MuiDrawer-root .logo,
#root .MuiDrawer-root.RaSidebar-paperAnchorLeft .logo {
  margin-bottom: 2rem;
}
#root .custom-menu {
  height: 100%;
  margin: 0;
  padding: 2.4rem 2.2rem 0;
  min-height: 540px;
}
#root .custom-menu .MuiMenuItem-root {
  color: #000000;
  padding-left: 0;
}
#root .custom-menu .MuiMenuItem-root:after {
  content: "";
  display: block;
  height: 1px;
  background: #BAAEAE;
  position: absolute;
  width: calc(100% - 45px);
  margin-left: 45px;
  bottom: 0;
}
#root .custom-menu .MuiMenuItem-root .MuiListItemIcon-root {
  color: #000000;
  margin-right: 12px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  overflow: hidden;
}
#root .custom-menu .MuiMenuItem-root .MuiSvgIcon-root {
  width: 21px;
  height: 21px;
}
#root .custom-menu .MuiMenuItem-root:hover {
  color: #000000;
}
#root .custom-menu .MuiMenuItem-root:hover .MuiListItemIcon-root {
  color: #0001BE;
  background-color: rgba(0, 1, 190, 0.15);
}
#root .custom-menu .MuiMenuItem-root.RaMenuItemLink-active {
  color: #000000;
}
#root .custom-menu .MuiMenuItem-root.RaMenuItemLink-active:after {
  background: #BAAEAE;
}
#root .custom-menu .MuiMenuItem-root.RaMenuItemLink-active .MuiListItemIcon-root {
  color: #FFFFFF;
  background: #0001BE;
}
#root .custom-menu .sub-menu {
  margin: 0;
  padding: 0;
}
#root .custom-menu .sub-menu .MuiListItemIcon-root {
  color: rgba(0, 0, 0, 0.5);
  margin: 9px 17px 9px 9px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  overflow: hidden;
}
#root .custom-menu .sub-menu .MuiMenuItem-root:hover {
  color: #000000;
}
#root .custom-menu .sub-menu .MuiMenuItem-root:hover .MuiListItemIcon-root {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 1, 190, 0.15);
}
#root .custom-menu .sub-menu .MuiMenuItem-root.RaMenuItemLink-active {
  color: #000000;
}
#root .custom-menu .sub-menu .MuiMenuItem-root.RaMenuItemLink-active:after {
  background: transparent;
}
#root .custom-menu .sub-menu .MuiMenuItem-root.RaMenuItemLink-active .MuiListItemIcon-root {
  color: #0001BE;
  background-color: rgba(0, 1, 190, 0.15);
}
#root .custom-menu .sub-menu .MuiSvgIcon-root {
  width: 8px;
  height: 8px;
}
#root .custom-menu .logout-link {
  margin-top: auto;
  margin-bottom: 1.5rem;
  border-bottom: 0;
  border-top: 1px solid #f5f5f5;
}
@media screen and (max-height: 540px) {
  #root .custom-menu .logout-link {
    margin-top: 12px;
  }
}
#root .custom-menu .logout-link:after {
  display: none;
}
@media (max-width: 599px) {
  #root .RaSidebar-modal {
    height: 100%;
    margin: 0;
    padding: 2.4rem 2.4rem 0 !important;
  }
  #root .RaSidebar-modal .MuiMenuItem-root {
    color: rgba(245, 245, 245, 0.7) !important;
  }
}
#root .RaSimpleShowLayout-stack .RaLabeled-label span {
  font-size: 24px !important;
  margin-bottom: 0.4em !important;
  font-weight: 500 !important;
}
#root .custom-list .MuiCard-root,
#root .list-page .MuiCard-root {
  box-shadow: none;
}
#root .RaDatagrid-table {
  table-layout: auto;
  /*
    .RaDatagrid-rowCell {
      background-color: @light-grey;
      border-top: 2px solid @white;
      border-bottom: 2px solid @white;
    }
    */
}
#root .RaDatagrid-table .RaDatagrid-headerCell,
#root .RaDatagrid-table .MuiTableCell-head {
  background-color: #2A2B1A;
  color: #f5f5f5;
  border-radius: 0;
  height: 3.125rem;
}
#root .RaDatagrid-table .RaDatagrid-headerCell .MuiTableSortLabel-icon,
#root .RaDatagrid-table .MuiTableCell-head .MuiTableSortLabel-icon,
#root .RaDatagrid-table .RaDatagrid-headerCell .MuiTableSortLabel-root.Mui-active,
#root .RaDatagrid-table .MuiTableCell-head .MuiTableSortLabel-root.Mui-active,
#root .RaDatagrid-table .RaDatagrid-headerCell .MuiTableSortLabel-icon.Mui-active,
#root .RaDatagrid-table .MuiTableCell-head .MuiTableSortLabel-icon.Mui-active {
  color: #f5f5f5;
}
#root .RaDatagrid-table .RaDatagrid-headerCell .MuiButtonBase-root.MuiTableSortLabel-root:hover,
#root .RaDatagrid-table .MuiTableCell-head .MuiButtonBase-root.MuiTableSortLabel-root:hover {
  color: #FFFFFF;
}
#root .RaDatagrid-table thead .MuiSvgIcon-root {
  color: #FFFFFF;
}
#root .table-action tbody td:last-child {
  width: 65px;
  text-align: center;
}
#root .cell-action {
  width: 65px;
  text-align: center;
}
#root .MuiTablePagination-root .MuiToolbar-regular {
  flex-direction: row;
}
#root .RaBulkActionsToolbar-toolbar.MuiToolbar-regular {
  flex-direction: row;
}
#root .delete-button .MuiButton-startIcon {
  margin: 0;
  color: #000000;
}
#root .edit-button .MuiButton-startIcon {
  margin: 0;
  color: #000000;
}
#root .table-results {
  width: 100%;
  table-layout: auto;
  margin-bottom: 20px;
}
#root .table-results th {
  text-align: left;
  width: 20%;
}
#root .table-results th,
#root .table-results td {
  padding: 3px 15px 3px 0;
}
#root .table-datagrid-style {
  width: 100%;
  table-layout: auto;
  margin-bottom: 20px;
  border-spacing: 1px;
}
#root .table-datagrid-style th,
#root .table-datagrid-style td {
  padding: 2px 12px;
  text-align: center;
  height: 38px;
}
#root .table-datagrid-style th {
  background-color: #2A2B1A;
  color: #f5f5f5;
  border-radius: 0;
  width: 20%;
  font-weight: 400;
}
#root .table-datagrid-style td {
  background-color: #FFFFFF;
}
.button-primary {
  background-color: #0001BE !important;
  color: #FFFFFF !important;
  font-weight: 700 !important;
  text-transform: none !important;
}
.button-primary:hover {
  background-color: #2A2B1A !important;
}
.button-medium {
  font-size: 16px !important;
  padding: 6px 16px !important;
}
.button-over-circle {
  margin: 5px !important;
  min-width: 36px !important;
  height: 36px;
  border-radius: 99px !important;
}
.button-over-circle span {
  margin: 0 !important;
}
.button-over-circle:hover {
  background-color: rgba(0, 1, 190, 0.15) !important;
  min-width: 36px !important;
  height: 36px;
  border-radius: 99px !important;
}
#root {
  /* tab list style */
  /* dropdown */
}
#root .show-page .RaShow-main > .MuiPaper-elevation {
  box-shadow: none;
  border: none;
}
#root .no-padding .RaTabbedShowLayout-content {
  padding: 0 !important;
}
#root .MuiTabs-root {
  min-height: 40px;
}
#root .MuiTabs-root + .MuiDivider-root {
  display: none;
}
#root .MuiTab-root.MuiButtonBase-root {
  background-color: rgba(0, 1, 190, 0.1);
  color: #0001BE;
  margin-right: 3px;
  padding: 2px 18px;
  min-height: 40px;
  min-width: 40px;
  column-gap: 12px;
  border: none;
}
#root .MuiTab-root.MuiButtonBase-root.add-tab {
  padding: 2px;
  margin-right: 0;
}
#root .MuiTab-root.MuiButtonBase-root.Mui-selected,
#root .MuiTab-root.MuiButtonBase-root.opened {
  background-color: #0001BE;
  color: #FFFFFF;
}
#root .MuiTab-root.MuiButtonBase-root.finished {
  background-color: rgba(0, 190, 17, 0.25);
  color: #055E0D;
}
#root .MuiTab-root.MuiButtonBase-root.Mui-selected.finished {
  background-color: #00BE11;
  color: #FFFFFF;
}
#root .MuiTab-root.MuiButtonBase-root.error {
  background-color: rgba(211, 47, 47, 0.25);
  color: #da0c1d;
}
#root .MuiTab-root.MuiButtonBase-root.Mui-selected.error {
  background-color: #d32f2f;
  color: #FFFFFF;
}
#root .MuiTabs-indicator {
  display: none;
}
#root .MuiTabs-root,
#root .MuiTabs-scroller {
  overflow: visible !important;
}
#root .MuiTabs-flexContainer {
  position: relative;
  display: inline-flex;
  overflow: visible;
  flex-wrap: wrap;
  row-gap: 0.2rem;
}
#root .tab-dropdown {
  background-color: #0001BE;
  position: absolute;
  z-index: 999999;
  top: 100%;
  left: 0;
  max-height: 240px;
  overflow: auto;
  border-radius: 0;
  min-width: 200px;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
#root .tab-dropdown::-webkit-scrollbar {
  width: 0.5rem;
}
#root .tab-dropdown::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid #8984e0;
}
#root .tab-dropdown::-webkit-scrollbar-thumb {
  background: #8984e0;
}
#root .tab-dropdown::-webkit-scrollbar-thumb:hover {
  background: #B8B6EB;
}
#root .tab-dropdown ul {
  margin: 0;
  padding: 12px 20px;
  list-style: none;
  color: #FFF;
}
#root .tab-dropdown ul li {
  cursor: pointer;
  margin-bottom: 3px;
  text-transform: none;
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
}
#root .tab-dropdown ul li:last-child {
  margin: 0;
}
#root .RaBulkActionsToolbar-toolbar {
  min-height: 54px;
  height: 54px;
  -webkit-transform: translateY(-54px);
  -moz-transform: translateY(-54px);
  -ms-transform: translateY(-54px);
  transform: translateY(-54px);
}
#root .RaBulkActionsToolbar-collapsed {
  min-height: 0;
  height: 0;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  overflow-y: hidden;
}
#root .MuiToolbar-regular {
  display: flex;
  flex-direction: column;
}
#root .toptoolbar {
  flex: 1 auto;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  order: -1;
  padding-top: 0;
}
#root .toptoolbar__nav {
  display: flex;
  align-items: flex-start;
  column-gap: 12px;
}
#root .toptoolbar__backlink {
  margin-top: 3px;
  border-radius: 0;
  color: #000000;
  border: 2px solid #000000;
  padding: 4px;
}
#root .toptoolbar__backlink:hover {
  background-color: #f5f5f5;
}
#root .toptoolbar__backlink .MuiSvgIcon-root {
  font-size: 16px;
}
#root .toptoolbar__title {
  font-size: 20px;
  font-weight: 700;
}
#root .toptoolbar__buttons {
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
}
#root .MuiBreadcrumbs-root {
  font-size: 12px;
  font-weight: 600;
  margin-top: 3px;
}
#root .MuiBreadcrumbs-root .MuiBreadcrumbs-separator,
#root .MuiBreadcrumbs-root .MuiBreadcrumbs-li {
  font-weight: 600;
  color: #000000;
}
#root .MuiBreadcrumbs-root .MuiBreadcrumbs-li a {
  color: #000000;
  text-decoration: none;
}
#root .MuiBreadcrumbs-root .MuiBreadcrumbs-li a:hover {
  color: #0001BE;
}
#root .RaFilterForm-root {
  width: 100%;
}
#root .footertoolbar {
  flex-direction: row;
  padding: 18px 0;
  background: #FFF;
}
#root .wifi-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(186, 174, 174, 0.3);
}
#root .wifi-icon.wifi-off {
  background-color: #F7DCDC;
}
#root .simple-form {
  background-color: #f5f5f5;
  padding: 30px 25px 10px;
  width: 100%;
}
#root .simple-form.simple-form-tab {
  margin: 0 -16px;
}
#root .two-columns-form .MuiStack-root,
#root .two-columns-form .fieldset-input-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 5%;
  max-width: 850px;
}
#root .two-columns-form .labeled-input {
  width: 47.5%;
}
@media (max-width: 899px) {
  #root .two-columns-form .labeled-input {
    width: 100%;
  }
}
#root .labeled-input {
  width: 100%;
}
#root .labeled-input .MuiFormControl-root {
  margin: 0;
}
#root .RaCreate-card .MuiToolbar-regular {
  flex-direction: row;
  background-color: #FFFFFF;
}
#root .outer-label > .MuiInputLabel-root {
  position: relative;
  transform: none;
  line-height: 1.5;
}
#root .outer-label > .MuiInputLabel-root span {
  color: #2A2B1A;
  font-size: 14px;
  margin-bottom: 3px;
}
#root .outer-label .MuiOutlinedInput-notchedOutline {
  height: 40px;
  top: 0;
}
#root .outer-label .MuiOutlinedInput-notchedOutline legend {
  display: none;
}
#root .no-label {
  /*
    > .MuiInputLabel-root {
      display: none;
    }
    */
}
#root .no-label .MuiInputLabel-shrink {
  display: none;
}
#root .no-label .MuiOutlinedInput-notchedOutline {
  height: 40px;
  top: 0;
}
#root .no-label .MuiOutlinedInput-notchedOutline legend {
  display: none;
}
#root .datepicker-range {
  display: flex;
  column-gap: 12px;
}
#root .form-row {
  display: flex;
  column-gap: 1px;
  width: 100%;
  margin: 8px 0;
}
#root .form-col {
  flex: 1 1 auto;
  width: 150px;
  padding: 2px 12px;
  background-color: #2A2B1A;
  color: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#root .form-col .MuiButtonBase-root {
  color: #F9F9F9;
}
#root .form-col .MuiButtonBase-root.Mui-disabled {
  color: #888888;
}
#root .add-col {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}
#root .add-col .MuiButtonBase-root {
  width: auto;
  min-width: 20px;
}
#root .add-col .MuiButton-startIcon {
  margin-right: 0;
}
#root .iterator-horizontal .RaSimpleFormIterator-line {
  border-bottom: none;
}
#root .iterator-horizontal .RaSimpleFormIterator-line:first-child {
  margin-top: 6px;
}
#root .iterator-horizontal .RaSimpleFormIterator-form {
  display: flex;
  flex-direction: row;
  column-gap: 1px;
  width: 100%;
  padding-right: 12px;
}
#root .iterator-horizontal .MuiFormControl-root {
  padding: 0 5px;
}
#root .overlay {
  width: 100%;
  height: 100%;
}
#root .modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}
#root .modal__content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  display: flex;
  flex-direction: column;
  background: #FFF;
  min-height: 4rem;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  padding: 16px 32px;
  margin: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
#root .modal__header {
  border-bottom: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
#root .modal__header.no-title {
  border-bottom: none;
  margin-bottom: 0;
  justify-content: flex-end;
}
#root .modal__title {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 !important;
}
#root .modal__body {
  overflow: auto;
}
#root .modal__body .MuiCardContent-root {
  padding: 10px 0 0;
  background-color: #FFFFFF !important;
}
#root .modal__body .footertoolbar {
  justify-content: center;
  padding: 0;
}
/* Page styles */
#root .RaLogin-root {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../images/login-bg.png);
  background-size: cover;
}
#root .RaLogin-card {
  width: 100%;
  max-width: 510px;
  text-align: center;
  padding: 60px 80px;
  box-shadow: 0 28px 35px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
}
#root .RaLogin-card .logo-container {
  flex-shrink: 0;
  margin-bottom: 35px;
}
#root .RaLogin-card form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
#root .RaLogin-card form input {
  font-size: 16px;
  background-color: #FFFFFF;
  border: none;
  padding: 8px 16px;
  color: #000000;
}
#root .RaLogin-card form span.error {
  font-size: 12px;
  margin: -5px 14px 0;
  text-align: left;
}
#root .fieldset {
  margin: 10px 0;
}
#root .custom-iterator-header {
  display: flex;
  background-color: #444;
  color: #f5f5f5;
  column-gap: 24px;
  padding: 12px 24px;
}
#root .custom-iterator-header div:first-child {
  width: 50%;
}
#root .custom-iterator .RaSimpleFormIterator-list {
  padding: 5px 5px 5px 0;
}
#root .custom-iterator .RaSimpleFormIterator-line {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
#root .custom-iterator .RaSimpleFormIterator-form {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  width: 90%;
}
#root .custom-iterator .RaSimpleFormIterator-action {
  margin-top: 15px;
  text-align: center;
  width: 10%;
}
#root .custom-iterator .MuiFormHelperText-root {
  display: none;
}
#root .custom-iterator-vertical .custom-iterator-header {
  width: 100%;
  margin-bottom: 12px;
}
#root .custom-iterator-vertical .custom-iterator-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  padding: 0;
}
#root .custom-iterator-vertical .RaSimpleFormIterator-list {
  padding: 5px 0;
}
#root .custom-iterator-vertical .RaSimpleFormIterator-form {
  display: flex;
  flex: 1 auto;
  width: 95%;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 16px;
}
#root .custom-iterator-vertical .RaSimpleFormIterator-form .MuiTextField-root {
  width: 47.5% !important;
}
#root .custom-iterator-vertical .RaSimpleFormIterator-form .custom-iterator-header + .MuiTextField-root {
  padding-left: 0;
}
#root .custom-iterator-vertical .RaSimpleFormIterator-action {
  margin-top: 5px;
  text-align: center;
  flex-shrink: 0;
  min-width: 35px;
  width: 5%;
}
#root .custom-iterator-horizontal .custom-iterator-row-label {
  margin-top: 8px;
}
#root .custom-iterator-horizontal .custom-iterator-row {
  flex-direction: row;
  align-items: flex-start;
  column-gap: 16px;
  padding: 0;
}
#root .custom-iterator-horizontal .RaSimpleFormIterator-form {
  flex-wrap: nowrap !important;
}
#root .custom-iterator-horizontal .RaSimpleFormIterator-action {
  margin-top: 12px;
}
#root .custom-iterator-horizontal .MuiFormControlLabel-root {
  margin-right: 0;
}
#root .custom-iterator-horizontal .RaSimpleFormIterator-form {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  column-gap: 16px;
  padding: 0;
}
#root .fieldset-magnitud > .fieldset-magnitud {
  width: 50%;
}
#root .fieldset-magnitud .custom-iterator .custom-iterator-field:first-child {
  width: 55%;
}
#root .fieldset-magnitud .custom-iterator .MuiFormControl-root label {
  display: inline-flex !important;
}
#root .fieldset-checklist {
  width: 100%;
}
#root .fieldset-observations {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5%;
}
#root .fieldset-observations .fieldset-title {
  width: 100%;
  flex: 1 0 100%;
}
#root .fieldset-observations .labeled-input {
  width: 47.5% !important;
}
#root .fieldset-two-columns {
  max-width: 850px;
  width: 100%;
}
#root .fieldset-two-columns > .fieldset-two-columns {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5%;
}
#root .fieldset-two-columns .fieldset-title {
  width: 100%;
  flex: 1 0 100%;
}
#root .fieldset-two-columns .MuiStack-root {
  width: 47.5% !important;
}
#root .fieldset-two-columns .RaRichTextInput-editorContent .ProseMirror {
  min-height: 5rem !important;
}
#root .fieldset-two-columns-auto {
  max-width: 850px;
  width: 100%;
  display: flex;
  column-gap: 10%;
}
#root .RaFileInput-dropZone {
  border-radius: 8px;
  border: 2px dashed #BAAEAE;
}
#root .dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 6px;
  padding: 12px;
}
#root .dropzone .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
}
#root .dropzone p {
  margin: 0;
}
#root .patterns-form {
  background-color: #f5f5f5;
  padding: 30px 25px 10px;
}
#root .patterns-form .custom-iterator .RaSimpleFormIterator-list {
  padding: 5px;
}
#root .patterns-form .custom-iterator .RaSimpleFormIterator-line {
  display: flex;
  align-items: center;
  border-bottom: none;
}
#root .patterns-form .custom-iterator .RaSimpleFormIterator-form {
  display: flex;
  flex-direction: row;
  column-gap: 5%;
  width: 90%;
  max-width: 850px;
  align-items: center;
}
#root .patterns-form .custom-iterator .unit {
  text-align: center;
  width: 10%;
}
#root .patterns-form .RaSimpleFormIterator-action svg {
  width: 0.8em;
  height: 0.8em;
}
#root .pattern-search {
  position: sticky;
  top: 0;
  width: 85%;
  z-index: 99;
  padding: 5px 0;
  background-color: #FFF;
}
#root .pattern-search .MuiFormHelperText-root {
  display: none;
}
#root .pattern-checkbox-group {
  padding-top: 5px;
  padding-bottom: 24px;
}
#root .pattern-search-footer {
  position: fixed;
  width: 90%;
  bottom: 0;
}
#root .select-patterns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 15px;
}
#root .selected-patterns-header {
  margin-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: auto;
  border-bottom: 1px solid #2A2B1A;
  display: block;
  width: 100%;
}
#root .selected-patterns-list-item {
  background-color: #FFFFFF;
  margin-bottom: 3px;
  width: auto;
}
#root .selected-patterns-list-item p {
  padding: 0.7rem 1rem;
  margin: 0;
}
#root .selected-patterns-list-item .button {
  min-width: 40px;
  max-width: 40px;
  padding: 0;
  margin: 4px 5px;
}
#root .selected-patterns-list-item .button span {
  margin: 0;
}
#root .selected-patterns-list-item .button :hover {
  min-width: 40px;
  background-color: #0001BE;
  color: #FFFFFF;
  border-radius: 99px;
}
#root .selected-patterns-list-item:first-child {
  border-bottom: 1px solid #2A2B1A;
}
