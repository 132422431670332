#root {

  .overlay {
    width: 100%;
    height: 100%;
  }

  .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    background: fade(@dark, 50%);


    &__content {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: fixed;
      display: flex;
      flex-direction: column;
      background: #FFF;
      min-height: 4rem;
      width: 90%;
      max-width: 600px;
      max-height: 90vh;
      padding: 16px 32px;
      margin: 0;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    // Modal header
    &__header {
      //padding: 12px 24px;
      border-bottom: 1px solid @dark;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      &.no-title {
        border-bottom: none;
        margin-bottom: 0;
        justify-content: flex-end;
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      margin: 0 !important;
    }

    //&__close {}

    // Modal body
    &__body {
      overflow: auto;

      .MuiCardContent-root {
        padding: 10px 0 0;
        background-color: @white !important;
      }

      .footertoolbar {
        justify-content: center;
        padding: 0;
      }
    }
  }
}