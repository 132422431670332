#root {

  // GENERAL STYLES
  .fieldset {
    margin: 10px 0;
  }

  .custom-iterator-header {
    display: flex;
    background-color: @grey;
    color: @light-grey;
    column-gap: 24px;
    padding: 12px 24px;

    div:first-child {
      width: 50%;
    }
  }

  .custom-iterator {

    .RaSimpleFormIterator-list {
      padding: 5px 5px 5px 0;
    }

    .RaSimpleFormIterator-line {
      display: flex;
      margin-bottom: 10px;
      padding-bottom: 10px;
      //align-items: center;
    }

    .RaSimpleFormIterator-form {
      display: flex;
      flex-direction: row;
      column-gap: 24px;
      width: 90%;
    }

    .RaSimpleFormIterator-action {
      margin-top: 15px;
      text-align: center;
      width: 10%;
    }

    .MuiFormHelperText-root {
      display:none;
    }
  }

  .custom-iterator-vertical {

    .custom-iterator-header {
      width: 100%;
      margin-bottom: 12px;
    }

    .custom-iterator-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      column-gap: 24px;
      padding: 0;
      //background-color: @grey;
    }

    .RaSimpleFormIterator-list {
      padding: 5px 0;
    }

    .RaSimpleFormIterator-form {
      display: flex;
      flex: 1 auto;
      width: 95%;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 16px;


      .MuiTextField-root {
        width: 47.5% !important;
      }

      .custom-iterator-header + .MuiTextField-root {
        padding-left: 0;
      }
    }

    .RaSimpleFormIterator-action {
      margin-top: 5px;
      text-align: center;
      flex-shrink: 0;
      min-width: 35px;
      width: 5%;
    }
  }

  .custom-iterator-horizontal {

    .custom-iterator-row-label {
      margin-top: 8px;
    }

    .custom-iterator-row {
      flex-direction: row;
      align-items: flex-start;
      column-gap: 16px;
      padding: 0;
    }

    .RaSimpleFormIterator-form {
      flex-wrap: nowrap !important;
    }

    .RaSimpleFormIterator-action {
      margin-top: 12px;
    }

    .MuiFormControlLabel-root {
      margin-right:0;
    }



    // move from sx to styles
    .RaSimpleFormIterator-form{
      width: 100% !important;
      display: flex !important;
      flex-direction: row !important;
      column-gap: 16px;
      padding: 0;
    }
  }

  // STYLES FOR MAGNITUD FIELDSET
  .fieldset-magnitud {

    >.fieldset-magnitud {
      width: 50%;
    }

    .custom-iterator {

      .custom-iterator-field {

        &:first-child {
          width: 55%;
        }
      }


      // checkbox
      .MuiFormControl-root label {
        display: inline-flex !important;
      }
    }
  }

  // STYLES FOR CHECKLIST FIELDSET
  .fieldset-checklist {
    width: 100%;
  }

  // STYLES FOR OBSERVATIONS FIELDSET
  .fieldset-observations {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%;

    .fieldset-title {
      width: 100%;
      flex: 1 0 100%;
    }

    .labeled-input {
      width: 47.5% !important;
    }
  }

  .fieldset-two-columns {
    max-width: 850px;
    width:100%;

    >.fieldset-two-columns {
      display: flex;
      flex-wrap: wrap;
      column-gap: 5%;
    }

    .fieldset-title {
      width: 100%;
      flex: 1 0 100%;
    }

    .MuiStack-root {
      width: 47.5% !important;
    }

    .RaRichTextInput-editorContent .ProseMirror {
      min-height: 5rem !important;
    }
  }

  .fieldset-two-columns-auto {
    max-width: 850px;
    width: 100%;
    display: flex;
    column-gap: 10%;
  }

  // Image field
  .RaFileInput-dropZone {
    border-radius: 8px;
    border: 2px dashed @medium-grey;
  }
  .dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 6px;
    padding: 12px;

    .MuiSvgIcon-root {
      width: 30px;
      height: 30px;
    }
    p {
      margin: 0;
    }
  }
}