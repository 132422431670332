#root {
  .show-page {

    .RaShow-main {

      > .MuiPaper-elevation {
        box-shadow: none;
        border: none;
      }
    }
  }

  .no-padding {
    .RaTabbedShowLayout-content {
      padding: 0 !important;
    }
  }

  /* tab list style */
  .MuiTabs-root {
    min-height: 40px;

    & + .MuiDivider-root {
      display: none;
    }
  }

  .MuiTab-root.MuiButtonBase-root {
    background-color: fade(@primary, 10%);
    color: @primary;
    margin-right: 3px;
    padding: 2px 18px;
    min-height: 40px;
    min-width: 40px;
    column-gap: 12px;
    border: none;
  }

  .MuiTab-root.MuiButtonBase-root.add-tab {
    padding: 2px;
    margin-right: 0;
  }

  .MuiTab-root.MuiButtonBase-root.Mui-selected,
  .MuiTab-root.MuiButtonBase-root.opened  {
    background-color: @primary;
    color: @white;
  }

  .MuiTab-root.MuiButtonBase-root.finished {
    background-color: fade(@green, 25%);
    color: #055E0D;
  }
  .MuiTab-root.MuiButtonBase-root.Mui-selected.finished {
    background-color: @green;
    color: @white;
  }

  .MuiTab-root.MuiButtonBase-root.error {
    background-color: fade(@error, 25%);
    color: @red;
  }
  .MuiTab-root.MuiButtonBase-root.Mui-selected.error {
    background-color: @error;
    color: @white;
  }

  .MuiTabs-indicator {
    display: none;
  }

  /* dropdown */

  .MuiTabs-root,
  .MuiTabs-scroller {
    overflow: visible !important;
  }

  .MuiTabs-flexContainer {
    position: relative;
    display: inline-flex;
    overflow: visible;
    flex-wrap: wrap;
    row-gap: 0.2rem;
  }

  .tab-dropdown {
    background-color: @primary;
    position: absolute;
    z-index: 999999;
    top: 100%;
    left: 0;
    max-height: 240px;
    overflow: auto;
    border-radius: 0;
    min-width: 200px;
    // custom-scrollbar
    @custom-scrollbar-style();

    ul {
      margin: 0;
      padding: 12px 20px;
      list-style: none;
      color: #FFF;

      li {
        cursor: pointer;
        margin-bottom: 3px;
        text-transform: none;
        text-align: left;
        font-size: 16px;
        line-height: 1.5;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

}