#root {
  .RaLogin-root {
    width: 100%;
    height: 100vh;
    background-color: @light-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../images/login-bg.png);
    background-size: cover;
  }

  .RaLogin-card {
    width: 100%;
    max-width: 510px;
    //min-height: 470px;
    text-align: center;
    padding: 60px 80px;
    box-shadow: 0 28px 35px rgba(0, 0, 0, 0.2);
    background-color: @light-grey;

    .logo-container {
      flex-shrink: 0;
      margin-bottom: 35px;
    }

    form {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      input {
        font-size: 16px;
        background-color: @white;
        border: none;
        padding: 8px 16px;
        color: @black;
      }

      span.error {
        font-size: 12px;
        margin: -5px 14px 0;
        text-align: left;
      }
    }
  }
}
