.button-primary {
  background-color: @primary !important;
  color: @white !important;
  font-weight: 700 !important;
  text-transform: none !important;

  &:hover {
    background-color: @dark-grey !important;
  }
}

.button-medium {
  font-size: 16px !important;
  padding: 6px 16px !important;
}

// Patterns list delete button
.button-over-circle {
  margin:5px !important;
  min-width: 36px !important;
  height: 36px;
  border-radius: 99px !important;   

  span {
    margin:0 !important;
  }
  &:hover {
    background-color: fade(@primary, 15%) !important;
    min-width: 36px !important;
    height: 36px;
    border-radius: 99px !important;  
  }
}

