#root {

	@media @sm-up {
		.MuiAppBar-root {
			display: none;
		}

		.RaLayout-appFrame {
			margin-top: 0;
		}
	}

	#main-content {
		padding: 1.5rem;
		position: relative;
	}

	/* general components styles */

	// MUI card
	.MuiCard-root {
    margin-bottom: 12px;
    box-shadow: none !important;

    &.welcome {
        padding: 12px;

        .MuiCardHeader-root {
            font-size: 18px;
            text-transform: capitalize;
            padding: 0 !important;

            .MuiTypography-root {
                font-weight: 800;
            }
        }
        .MuiCardContent-root {
            padding: 0 !important;
        }
    }
	}
}