#root {

  // for bulk actions toolbar
  .RaBulkActionsToolbar-toolbar {
    min-height: 54px;
    height: 54px;
    -webkit-transform: translateY(-54px);
    -moz-transform: translateY(-54px);
    -ms-transform: translateY(-54px);
    transform: translateY(-54px);
  }

  .RaBulkActionsToolbar-collapsed {
    min-height: 0;
    height: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    overflow-y: hidden;
  }

  // for toolbars in general
  .MuiToolbar-regular {
    display: flex;
    flex-direction: column;
  }

  // top toolbar
  .toptoolbar {
    flex: 1 auto;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    order: -1;
    padding-top: 0;

    &__nav {
      display: flex;
      align-items: flex-start;
      column-gap: 12px;
    }

    &__backlink {
      margin-top: 3px;
      border-radius: 0;
      color: @dark;
      border: 2px solid @dark;
      padding: 4px;

      &:hover {
        background-color: @light-grey;
      }

      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }

    &__title {
      font-size: 20px;
      font-weight: 700;
    }

    &__buttons {
      display: flex;
      align-items: center;
      column-gap: 0.8rem;
    }
  }

  // breadcrumbs
  .MuiBreadcrumbs-root {
    font-size: 12px;
    font-weight: 600;
    margin-top: 3px;

    .MuiBreadcrumbs-separator,
    .MuiBreadcrumbs-li {
      font-weight: 600;
      color: @dark;
    }

    .MuiBreadcrumbs-li a {
      color: @dark;
      text-decoration: none;

      &:hover {
        color: @primary;
      }
    }
  }

  .RaFilterForm-root {
    width: 100%;
  }

  .footertoolbar {
    flex-direction: row;
    padding: 18px 0;
    background: #FFF;
  }

  .wifi-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color:fade(@medium-grey, 30%);
  }

  .wifi-icon.wifi-off {
    background-color: #F7DCDC;
  }
}