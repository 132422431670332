// For datgrid
#root {

  .RaSimpleShowLayout-stack {

    .RaLabeled-label span {
      font-size: 24px !important;
      margin-bottom: 0.4em !important;
      font-weight: 500 !important;
    }
  }

  .custom-list,
  .list-page {

    .MuiCard-root {
      box-shadow: none;
    }

  }

  .RaDatagrid-table {
    table-layout: auto;

    .RaDatagrid-headerCell,
    .MuiTableCell-head {
      background-color: @dark-grey;
      color: @light-grey;
      border-radius: 0;
      height: 3.125rem;

      .MuiTableSortLabel-icon,
      .MuiTableSortLabel-root.Mui-active,
      .MuiTableSortLabel-icon.Mui-active {
        color: @light-grey;
      }

      .MuiButtonBase-root.MuiTableSortLabel-root:hover {
        color: @white;
      }
    }

    /*
    .RaDatagrid-rowCell {
      background-color: @light-grey;
      border-top: 2px solid @white;
      border-bottom: 2px solid @white;
    }
    */

    thead .MuiSvgIcon-root {
      color: @white;
    }
  }

  // Customize grid with delete button in last cell
  .table-action {
    tbody td:last-child {
      //text-align: right;
      width: 65px;
      text-align: center;
    }
  }

  // in case of more than one action
  .cell-action {
    width: 65px;
    text-align: center;
  }

  //pagination toolbar
  .MuiTablePagination-root {

    .MuiToolbar-regular {
      flex-direction: row;
    }
  }

  // Bulk actions toolbar
  .RaBulkActionsToolbar-toolbar.MuiToolbar-regular {
    flex-direction: row;
  }

  // Delete Button
  .delete-button {

    .MuiButton-startIcon {
      margin: 0;
      color: @dark;
    }
  }

   // Edit Button
   .edit-button {

    .MuiButton-startIcon {
      margin: 0;
      color: @dark;
    }
  }

  // table-results
  .table-results {
    width: 100%;
    table-layout: auto;
    margin-bottom: 20px;

    th {
      text-align: left;
      width: 20%;
    }

    th, td {
      padding: 3px 15px 3px 0;
    }
  }

  //table-datagrid-style
  .table-datagrid-style {
    width: 100%;
    table-layout: auto;
    margin-bottom: 20px;
    border-spacing: 1px;

    th, td {
      padding: 2px 12px;
      text-align: center;
      height: 38px;
    }

    th {
      background-color: @dark-grey;
      color: @light-grey;
      border-radius: 0;
      width: 20%;
      font-weight: 400;
    }

    td {
      background-color: @white;
    }
  }
}