#root {
    .pattern-search {
        position:sticky;
        top: 0;
        width:85%;
        z-index: 99;
        padding: 5px 0;
        background-color: #FFF;

        .MuiFormHelperText-root {
            display:none;
        }
    }
    .pattern-checkbox-group {
        //top:48px;
        padding-top: 5px;
        padding-bottom: 24px;
    }
    .pattern-search-footer {
        position:fixed;
        width:90%;
        bottom:0;
    }
    .select-patterns {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-right: 15px;
    }
    .selected-patterns-header {
        margin-top:15px;
        margin-bottom: 10px;
        padding-bottom:10px;
        width: auto;
        border-bottom:1px solid @dark-grey;
        display:block;
        width: 100%;
    }
    .selected-patterns-list-item {
        background-color: @white;
        margin-bottom:3px;
        width: auto;

        p {
            padding: 0.7rem 1rem;
            margin: 0;
        }
        .button {
            min-width: 40px;
            max-width:40px;
            padding:0;
            margin:4px 5px;

            span {
                margin:0;
            }
            :hover {
                min-width: 40px;
                background-color: @primary;
                color: @white;
                border-radius:99px;
            }
        }
    }
    .selected-patterns-list-item:first-child {
        border-bottom: 1px solid @dark-grey;
    }
}