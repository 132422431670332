/* Sidebar */
#root {

  .MuiDrawer-root,
  .MuiDrawer-root.RaSidebar-paperAnchorLeft {
    //background-color: @primary;
    height: 100vh;
    position: sticky;
    top: 0;

    .RaSidebar-fixed {
      height: 100vh;
      position: static;
    }


    .logo {
      margin-bottom: 2rem;
    }
  }

  .custom-menu {
    height: 100%;
    margin: 0;
    padding: 2.4rem 2.2rem 0;
    min-height: 540px;

    .MuiMenuItem-root {
      color: @dark;
      //padding: 10px 16px 0;
      padding-left: 0;
      //border-bottom: 1px solid @white;

      &:after {
        content: "";
        display: block;
        //border-bottom: 1px solid fade(@light-grey,70%);
        height: 1px;
        background: @medium-grey;
        position: absolute;
        width: calc(100% - 45px);
        margin-left: 45px;
        bottom: 0;
      }

      .MuiListItemIcon-root {
        color: @dark;
        //margin: 0.3rem 0;
        margin-right: 12px;
        //padding: 0.3rem;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: auto;
        overflow: hidden;
      }

      .MuiSvgIcon-root {
        width: 21px;
        height: 21px;
        //font-size: 1.4rem;
      }

      &:hover {
        color: @dark;

        .MuiListItemIcon-root {
          //color: @white;
          //background-color: fade(@grey, 65%);
          color: @primary;
          background-color: fade(@primary, 15%);
        }
      }

      &.RaMenuItemLink-active {
        color: @dark;

        &:after {
          background: @medium-grey;
        }

        .MuiListItemIcon-root {
          color: @white;
          background: @primary;
        }
      }
    }

    .sub-menu {
      margin: 0;
      padding: 0;

      .MuiListItemIcon-root {
        color:fade(@dark, 50%);
        //margin-right: 8px;
        margin: 9px 17px 9px 9px;
        //padding: 0.3rem;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: auto;
        overflow: hidden;
      }

      .MuiMenuItem-root {
        &:hover {
          color: @dark;

          .MuiListItemIcon-root {
            color: fade(@dark, 50%);
            background-color: fade(@primary, 15%);
          }
        }

        &.RaMenuItemLink-active {
          color: @dark;

          &:after {
            //background-color: fade(@primary, 15%);
            background: transparent;
          }

          .MuiListItemIcon-root {
            color: @primary;
            background-color: fade(@primary, 15%);
          }
        }
      }

      .MuiSvgIcon-root {
        width: 8px;
        height:8px;
        //font-size: 1.4rem;
      }



    }

    .logout-link {
      margin-top: auto;
      margin-bottom: 1.5rem;
      border-bottom: 0;
      border-top: 1px solid @light-grey;

      @media screen and (max-height: 540px) {
        margin-top: 12px;
      }

      &:after {
        display: none;
      }
    }
  }

  @media @sm-down {
    .RaSidebar-modal {
      height: 100%;
      margin: 0;
      padding: 2.4rem 2.4rem 0 !important;

      .MuiMenuItem-root {
        color: fade(@light-grey, 70%) !important;
      }
    }
  }

  /*
  @media (max-width: 767px) {
    .MuiDrawer-root.RaSidebar-paperAnchorLeft {
      background-color: @primary;
    }
  }*/
}