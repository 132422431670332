#root {

  // Tipography
  .title-medium {
    font-size: 20px;
    font-weight: 700;
  }

  // Others

  .hide {
    display: none !important;
  }

  :focus-visible {
    outline: none !important;
  }

  .transparent {
    background: transparent !important;
  }

  .no-shadow {
    box-shadow: none !important;
  }

  /*
  table  {
    border: 1px solid black;
    td, th  {
      border: 1px solid black;
    }
  }
  */

  .empty-row {
    height: 30px;
  }

  .empty {
    //width: 100%;
    text-align: center;
    padding-top: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;

    p {
      @media (max-width: 599px) {
        font-size: 20px;
      }
    }
  }

  .pointer {
    cursor: pointer !important;
  }

  .flexbox {
    display: flex;
  }

  .error {
    color: @error;
  }

  .no-margin {
    margin: 0px !important;
  }

  .align-center {
    align-items: center;
  }
}

.empty-list {
  text-align: center;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  &__text {
    font-size: 24px;

    @media @sm-down {
      font-size: 20px;
    }
  }
}

.alert-panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 auto;
  padding: 24px 24px 48px;

  &__content {
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 12px;
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #F7DCDC;
  }

  p {
    font-size: 24px;
  }
}

.cache-icon {
  padding: 4px 20px !important;
  border-radius: 0 !important;
  color: rgba(0, 0, 0, 0.70) !important;

  &.cached {
    color: @primary !important;
  }
}

@custom-scrollbar-style: {
  /* width */
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid #8984e0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #8984e0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #B8B6EB;
  }

};