#root {

  .simple-form {
    background-color: @light-grey;
    padding: 30px 25px 10px;
    width: 100%;

    &.simple-form-tab {
      margin: 0 -16px;
    }
  }

  .two-columns-form {

    .MuiStack-root, .fieldset-input-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 5%;
      max-width: 850px;
    }

    //.MuiTextField-root,
    .labeled-input {
      width: 47.5%;
      //flex: 1 auto;

      @media @md-down {
        width: 100%;
      }
    }
  }

  // Default width for labeled inputs
  .labeled-input {
    width: 100%;

    .MuiFormControl-root {
      margin: 0;
    }
  }

  // Styles for te bottom bar (buttons)
  .RaCreate-card {

    .MuiToolbar-regular {
      flex-direction: row;
      background-color: @white;
    }
  }

  .outer-label {

    > .MuiInputLabel-root {
      position: relative;
      transform: none;
      line-height: 1.5;

      span {
        color: @dark-grey;
        font-size: 14px;
        margin-bottom: 3px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      height: 40px;
      top: 0;

      legend {
        display: none;
      }
    }
  }

  .no-label {

    //to remove placeholder
    /*
    > .MuiInputLabel-root {
      display: none;
    }
    */

    .MuiInputLabel-shrink {
      display: none;
    }

    .MuiOutlinedInput-notchedOutline {
      height: 40px;
      top: 0;

      legend {
        display: none;
      }
    }
  }

  // datepicker
  .datepicker-range {
    display: flex;
    column-gap: 12px;
  }

  // extra clases for iterartors in certificate form values
  .form-row {
    display: flex;
    column-gap: 1px;
    //width: calc(100% - 36px);
    width: 100%;
    margin: 8px 0;
  }

  .form-col {
    flex: 1 1 auto;
    width: 150px;
    padding: 2px 12px;
    background-color: @dark-grey;
    color: @light;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiButtonBase-root {
      color: @light;

      &.Mui-disabled {
        color: @alt-grey;
      }
    }
  }

  .add-col {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;

    .MuiButtonBase-root {
      width: auto;
      min-width: 20px;
    }

    .MuiButton-startIcon {
      margin-right: 0;
    }
  }

  .iterator-horizontal {

    .RaSimpleFormIterator-line {
      border-bottom: none;

      &:first-child {
        margin-top: 6px;
      }
    }

    .RaSimpleFormIterator-form {
      display: flex;
      flex-direction: row;
      column-gap: 1px;
      width: 100%;
      padding-right: 12px;
    }

    .MuiFormControl-root {
      padding: 0 5px;
    }
  }
}